
import { eoRendererMixin, dateFormatMixin, metaDataMixin } from '~/mixins'

export default {
  fetchOnServer: false,
  mixins: [
    eoRendererMixin,
    dateFormatMixin,
    metaDataMixin,
  ],
  async asyncData ({ $config, store, route, $api, params }) {
    const { podcast: podcastSlug = "" } = params
    const { data: {items: podcast = []}} = await $api.podcasts.getOneBySlug(podcastSlug, {
      domain: $config.preprEnvironmentDomainGeneriek,
    })

    store.dispatch('breadcrumbs/set', {
      path: route.path,
      eoRoot: true,
      width: 10,
      floating: true,
        items: [
        {
          to: '/podcasts',
          label: 'Podcasts',
        },
        {
          label: podcast[0].title,
        },
      ],
    })
    
    return {
      slug: podcastSlug,
      ...podcast[0],
    }
  },
  data () {
    return {
      title: null,
      content: [],
      intro: null,
      description: null,
      slug: null,
      logo: null,
      extraContent: [],
      activeTab: 0,
      seo: {},
      social: {},
    }
  },
  computed: {
    podcastData () {
      const { title, intro, description, slug, genre, logo, rssUrl, platforms } = this.$data
      return {
        title,
        intro,
        description,
        slug,
        genre,
        logo,
        rssUrl,
        podcastUrls: platforms,
        platforms,
      }
    },
    playingEpisode () {
      return this.$store.getters['podcastPlayer/selectedEpisode']
    },
  },
  watch: {
    'playingEpisode.npo.id': {
      handler: function (id) {
        this.$router.push({path: this.$route.path, query: {id}})
        if (this.playingEpisode && this.playingEpisode.podcast.slug == this.$route.params.podcast) {
          this.$route
          this.seo.title = `${this.playingEpisode.npo.title} - ${this.playingEpisode.podcast.title}`
          this.seo.description = this.playingEpisode.npo.description
          this.social.title = `${this.playingEpisode.npo.title} - ${this.playingEpisode.podcast.title}`
          this.social.description = this.playingEpisode.npo.description
        } else {
          this.seo = {}
          this.social = {}
        }
      },
    },
  },
}
